import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LicenseInfo } from '@mui/x-license';
import { ProjectsProvider } from 'api/projects/provider';
import AuthProvider from 'api/providers/authProvider';
import WorkspaceProvider from 'api/providers/workspaceProvider';
import Header from 'components/header';
import ModalProvider from 'components/modal';
import NotificationProvider from 'components/notify';
import { AccountPage } from 'pages/account';
import DatamaidPage from 'pages/datamaid';
import FinsightEntityPage from 'pages/finsightEntity';
import LoginPage from 'pages/login';
import ProjectsPage from 'pages/projects';
import ScratchpadPage from 'pages/scratchpad';
import DndProvider from 'providers/DnDProvider';
import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { darkTheme, lightTheme } from 'theme';

LicenseInfo.setLicenseKey(
  '05a2ce020d90053b6547efbd692fa010Tz1NaWNyb2FydCxFPTI3MjA5MjA2ODUzMjUsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

function App() {
  const darkMode = true;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <NotificationProvider>
          <DndProvider>
            <ProjectsProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    element={
                      <AuthProvider>
                        <Outlet />
                      </AuthProvider>
                    }
                  >
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                      element={
                        <WorkspaceProvider>
                          <ModalProvider>
                            <Header>
                              <Outlet />
                            </Header>
                          </ModalProvider>
                        </WorkspaceProvider>
                      }
                    >
                      <Route path="/" element={<span>loading...</span>} />
                      <Route path="/workspace/:workspaceId">
                        <Route
                          path=""
                          element={<span>nothing here, click projects</span>}
                        />
                        <Route path="*" element={<span>404</span>} />
                        <Route path="projects" element={<ProjectsPage />} />
                        <Route
                          path="entity/:id"
                          element={<FinsightEntityPage />}
                        />
                        <Route path="scratchpad" element={<ScratchpadPage />} />
                        <Route
                          path="scratchpad/:id"
                          element={<ScratchpadPage />}
                        />
                        <Route path="datamaid" element={<DatamaidPage />} />
                      </Route>
                      <Route path="/account" element={<AccountPage />} />
                    </Route>
                  </Route>
                </Routes>
              </BrowserRouter>
            </ProjectsProvider>
          </DndProvider>
        </NotificationProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
