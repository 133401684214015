/* eslint-disable */
import {
  AbsolutePriorityLevel,
  Commodity,
  Entity,
  Merchant,
  Project,
  Task,
} from './create';

export class GlobalStore {
  private static registeredEntities: Entity[] = [];
  private static registeredMerchants: Merchant[] = [];

  // cache
  private static cachedRootProjectEntities: Project[] = [];
  private static cachedProjectEntities: Project[] = [];
  private static cachedTaskEntities: Task[] = [];
  private static cachedCommodityEntities: Commodity[] = [];

  private static registeredEntityMap: Record<string, Entity> = {};
  private static registeredMerchantMap: Record<string, Merchant> = {};

  private static events = {
    topLevelUpdate: {} as Record<string, () => void>,
    export: {} as Record<
      string,
      (state: 'pending' | 'success' | 'idle') => void
    >,
  };

  private static pausedEvents: Record<keyof typeof this.events, boolean> = {
    topLevelUpdate: false,
    export: false,
  };

  // private static softUpdateEntityMap() {
  //   for (const key in this.registeredEntityMap) {
  //     const index = this.registeredEntityMap[key];
  //     const entity = this.registeredEntities[index];

  //     if (entity.id !== key) {
  //       delete this.registeredEntityMap[key];
  //       this.registeredEntityMap[entity.id] = index;
  //     }
  //   }
  // }

  private static exportDelay: number = 1000;
  private static queueExportTimeout: NodeJS.Timeout | null = null;

  private static queueExport() {
    // if export event is paused then do not queue exports
    if (this.pausedEvents.export) return;

    if (this.queueExportTimeout) {
      // state is inferred to be "pending"
      clearTimeout(this.queueExportTimeout);
    } else {
      this.fireEvent('export', 'pending');
    }

    this.queueExportTimeout = setTimeout(
      this.performExport.bind(this),
      this.exportDelay,
    );
  }

  private static performExport() {
    this.queueExportTimeout = null;
    this.exportAndSave();
    this.fireEvent('export', 'success');
    this.queueExportTimeout = setTimeout(
      (() => {
        this.fireEvent('export', 'idle');
        this.queueExportTimeout = null;
      }).bind(this),
      1000,
    );
  }

  private static exportAndSave() {
    const exportData = this.export();
    const exportString = JSON.stringify(exportData);

    window.localStorage.setItem('context', exportString);
  }

  private static fireEvent<T extends keyof typeof this.events>(
    event: T,
    args?: Parameters<(typeof this.events)[T][string]>[0],
  ) {
    // if event is paused then do nothing
    if (this.pausedEvents[event]) return;

    for (const key in this.events[event]) {
      this.events[event][key](args!);
    }
  }

  private static sortEntitiesPredicate(a: Entity, b: Entity) {
    return a.priority.compare(b.priority, b.id);
  }

  static onEvent<T extends keyof typeof this.events>(
    event: T,
    key: string,
    callback: (args: Parameters<(typeof this.events)[T][string]>[0]) => void,
  ) {
    this.events[event][key] = callback;
  }

  static get entities() {
    return this.registeredEntities;
  }

  static get rootProjects() {
    return this.cachedRootProjectEntities;
  }

  static get projects() {
    return this.cachedProjectEntities;
  }

  static get tasks() {
    return this.cachedTaskEntities;
  }

  static get commodities() {
    return this.cachedCommodityEntities;
  }

  static get merchants() {
    return this.registeredMerchants;
  }

  static registerEntity(entity: Entity) {
    this.registeredEntities = insertSorted(
      entity,
      this.registeredEntities,
      this.sortEntitiesPredicate,
    );

    // this.registeredEntities.push(entity);
    this.registeredEntityMap[entity.id] = entity;

    // attach event listeners
    entity.events.idUpdated.push((oldId, newId) => {
      const index = this.registeredEntityMap[oldId];
      delete this.registeredEntityMap[oldId];
      this.registeredEntityMap[newId] = index;
    });

    // if entity parent gets updated (as in changed)
    entity.events.parentUpdated.push((oldParent, newParent) => {
      if (oldParent && !newParent) {
        // If parent is removed
        this.cachedRootProjectEntities = insertSorted(
          entity,
          this.cachedRootProjectEntities,
          this.sortEntitiesPredicate,
        );
      } else if (!oldParent && newParent) {
        // if parent is added
        this.cachedRootProjectEntities = this.cachedRootProjectEntities.filter(
          (e) => e.id !== entity.id,
        );
      }
    });

    if (entity.type === 'project') {
      this.cachedProjectEntities = insertSorted(
        entity,
        this.cachedProjectEntities,
        this.sortEntitiesPredicate,
      );

      if (!entity.parent) {
        // All entites will NOT have a parent
        this.cachedRootProjectEntities = insertSorted(
          entity,
          this.cachedRootProjectEntities,
          this.sortEntitiesPredicate,
        );
      }
    } else if (entity.type === 'task') {
      this.cachedTaskEntities = insertSorted(
        entity,
        this.cachedTaskEntities,
        this.sortEntitiesPredicate,
      );
      this.cachedTaskEntities.push(entity);
    } else if (entity.type === 'commodity') {
      this.cachedCommodityEntities = insertSorted(
        entity as Commodity,
        this.cachedCommodityEntities,
        this.sortEntitiesPredicate,
      );
    }

    this.fireEvent('topLevelUpdate');
  }

  static registerMerchant(merchant: Merchant) {
    this.registeredMerchants.push(merchant);
    this.registeredMerchantMap[merchant.id] = merchant;

    // attach event listeners
    merchant.events.idUpdated.push((oldId, newId) => {
      const index = this.registeredMerchantMap[oldId];
      delete this.registeredMerchantMap[oldId];
      this.registeredMerchantMap[newId] = index;
    });

    this.fireEvent('topLevelUpdate');
  }

  static clear() {
    this.registeredEntities = [];
    this.registeredMerchants = [];
    this.cachedRootProjectEntities = [];
    this.cachedProjectEntities = [];
    this.cachedTaskEntities = [];
    this.cachedCommodityEntities = [];
    this.registeredEntityMap = {};
    this.registeredMerchantMap = {};

    this.fireEvent('topLevelUpdate');
  }

  static import(template: GlobalStoreTemplate) {
    this.pausedEvents.topLevelUpdate = true;

    for (const merchant of template.merchants) {
      Merchant.import(merchant);
    }

    for (const entity of template.entities) {
      Entity.import(entity);
    }

    this.pausedEvents.topLevelUpdate = false;
    this.fireEvent('topLevelUpdate');
  }

  static export(): GlobalStoreTemplate {
    return {
      entities: this.cachedRootProjectEntities.map((entity) => entity.export()),
      merchants: this.registeredMerchants.map((merchant) => merchant.export()),
    };
  }

  static getMerchantById(id: string): Merchant | undefined {
    return this.registeredMerchantMap[id];
  }

  static getEntityById(id: string): Entity | undefined {
    return this.registeredEntityMap[id];
  }

  static getCommodityById(id: string): Commodity | undefined {
    const commodity = this.getEntityById(id);
    if (commodity && commodity.type === 'commodity') {
      return commodity as Commodity;
    }
    return undefined;
  }

  static getProjectById(id: string): Project | undefined {
    const project = this.getEntityById(id);
    if (project && project.type === 'project') {
      return project as Project;
    }
    return undefined;
  }

  static getTaskById(id: string): Task | undefined {
    const task = this.getEntityById(id);
    if (task && task.type === 'task') {
      return task;
    }
    return undefined;
  }
}

export type GlobalStoreTemplate = {
  entities: EntityTemplate[];
  merchants: MerchantTemplate[];
};

export type EntityTemplate = ProjectTaskTemplate | CommodityTemplate;

export type ProjectTaskTemplate = {
  id: string;
  type: 'project' | 'task';
  name: string;
  description: string;
  priority: PriorityTemplate;
  budget?: BudgetTemplate;
  children: EntityTemplate[];
  deadline?: string;
};

export type CommodityTemplate = {
  id: string;
  type: 'commodity';
  name: string;
  brand: string;
  price: number;
  unit?: string;
  // merchant?: string;
  imageUrl?: string;
};

export type PriorityTemplate = {
  type: 'absolute';
  level: AbsolutePriorityLevel;
};

export type BudgetTemplate = {
  estimate: number;
  limit: number | null;
  transactions?: TransactionTemplate[];
};

export type TransactionTemplate = {
  id: string;
  name: string;
  amount: number;
  date: string;
  sources?: string[];
  merchant?: string;
  commodities?: TransactionCommodityTemplate[];
};

export type TransactionCommodityTemplate = {
  commodity: string;
  quantity: number;
};

export type MerchantTemplate = {
  id: string;
  name: string;
  url: string;
  description: string;
  logoUrl?: string;
  faviconLogo?: boolean;
};

// @ts-ignore
window.GlobalStore = GlobalStore;

function locationOf<T>(
  element: T,
  array: T[],
  comparer: (a: T, b: T) => number,
  start?: number,
  end?: number,
): number {
  if (array.length === 0) return -1;

  start = start || 0;
  end = end || array.length;
  var pivot = (start + end) >> 1; // should be faster than dividing by 2

  var c = comparer(element, array[pivot]);
  if (end - start <= 1) return c == -1 ? pivot - 1 : pivot;

  switch (c) {
    case -1:
      return locationOf(element, array, comparer, start, pivot);
    case 0:
      return pivot;
    case 1:
      return locationOf(element, array, comparer, pivot, end);
  }

  return -1;
}

function insertSorted2<T>(
  element: T,
  array: T[],
  comparer: (a: T, b: T) => number,
) {
  const index = locationOf(element, array, comparer);

  if (index === -1) {
    array.push(element);
  } else {
    array.splice(index, 0, element);
  }
  return array;
}

function insertSorted<T>(
  value: T,
  arr: T[],
  comparator: (a: T, b: T) => number,
) {
  let low = 0;
  let high = arr.length;

  while (low < high) {
    const mid = Math.floor((low + high) / 2);

    if (comparator(value, arr[mid]) < 0) {
      high = mid;
    } else {
      low = mid + 1;
    }
  }

  arr.splice(low, 0, value);
  return arr;
}
