import { blue } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { useDraggable } from 'providers/DnDProvider';
import React, { useEffect, useState } from 'react';

export default function DroppableList() {
  const dragContext = useDraggable();

  const [dropMode, setDropMode] = useState(false);

  useEffect(() => {
    if (!dragContext.dragItem) {
      setDropMode(false);
    }
  }, [dragContext.dragItem]);

  return (
    <motion.div
      initial={{
        height: 0,
      }}
      animate={{
        height: dropMode ? 16 : 8,
      }}
      onMouseEnter={() => {
        if (dragContext.dragItem && dragContext.dragItem.type === 'entity') {
          setDropMode(true);
        }
      }}
      onMouseLeave={() => {
        setDropMode(false);
      }}
    >
      <motion.div
        initial={{
          height: 0,
        }}
        animate={{
          height: dropMode ? '100%' : 0,
        }}
        style={{
          width: '100%',
          display: 'flex',
          overflow: 'hidden',
          alignItems: 'center',
        }}
      >
        <motion.div
          style={{
            width: 4,
            height: '100%',
            borderRadius: '100px',
            backgroundColor: blue[700],
          }}
        />

        <motion.div
          style={{
            height: 4,
            flexGrow: 1,
            backgroundColor: blue[700],
          }}
        />
        <motion.div
          style={{
            width: 4,
            height: '100%',
            borderRadius: '100px',
            backgroundColor: blue[700],
          }}
        />
      </motion.div>
    </motion.div>
  );
}
