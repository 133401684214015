"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEntityExtraFieldValue = getEntityExtraFieldValue;
var verifyType_1 = require("./verifyType");
function getEntityExtraFieldValue(field, value, resolveToFriendlyValue) {
    if (resolveToFriendlyValue === void 0) { resolveToFriendlyValue = false; }
    try {
        var newValue = (0, verifyType_1.verifyType)(field, value);
        if (resolveToFriendlyValue) {
            return resolveFieldValueToFriendlyValue(field, newValue);
        }
        return newValue;
    }
    catch (_a) {
        if (field.schema.default !== undefined) {
            var newValue = (0, verifyType_1.verifyType)(field, field.schema.default);
            if (resolveToFriendlyValue) {
                return resolveFieldValueToFriendlyValue(field, newValue);
            }
            return newValue;
        }
        else if (!field.schema.required) {
            return null;
        }
        else {
            throw new Error("Invalid value for field ".concat(field.name));
        }
    }
}
function resolveFieldValueToFriendlyValue(field, value) {
    if (field.schema.type === 'enum') {
        if (!Array.isArray(field.schema.properties))
            throw new Error();
        if (typeof value !== 'number')
            throw new Error();
        return field.schema.properties[value].name;
    }
    return value;
}
