import { Checkbox, Stack, Typography } from '@mui/material';
import React from 'react';
import { FinsightEntityField } from 'shared';
import { formatName } from 'utils/fields';

export function BooleanRenderer({
  field,
  value,
  editable,
  onChange,
}: {
  field: FinsightEntityField;
  value: unknown;
  editable?: boolean;
  onChange?: (value: unknown) => Promise<void>;
}) {
  if (editable) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <Checkbox
          checked={Boolean(value)}
          onChange={async (e) => {
            onChange?.(e.target.checked);
          }}
        />
        <Typography>{formatName(field.name)}</Typography>
      </Stack>
    );
  }

  if (typeof value !== 'string') return <Typography>Invalid String</Typography>;

  return <Typography>{value}</Typography>;
}
