import { EndpointUrl } from 'api/endpoint';
import { useCurrentUser } from 'api/providers/authProvider';
import { Workspace, WorkspaceCreateSchema } from 'shared';
import useSWR from 'swr';

export function useWorkspaces() {
  const user = useCurrentUser();

  return useSWR(
    EndpointUrl && user ? `${EndpointUrl}/workspace/list` : null,
    async (url) => {
      const response = await fetch(url, {
        headers: {
          'x-finsight-token': user?.token ?? '',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch workspaces');
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        throw new Error('Invalid response');
      }

      return data as Workspace[];
    },
  );
}

export function createWorkspace(data: unknown) {
  const payload = WorkspaceCreateSchema.parse(data);

  return fetch(`${EndpointUrl}/workspace`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-finsight-token': window.localStorage.getItem('token') ?? '',
    },
    body: JSON.stringify(payload),
  });
}
