"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionSchema = void 0;
var zod_1 = require("zod");
var entity_1 = require("./entity");
exports.TransactionSchema = entity_1.BaseEntitySchema.extend({
    type: zod_1.z.literal('transaction'),
    amount: zod_1.z.number().default(0),
    merchant: zod_1.z.string().default(''),
    credit: zod_1.z.boolean().default(false),
    parent: zod_1.z.union([entity_1.ProjectRefSchema, entity_1.TaskRefSchema]),
    commodities: zod_1.z.array(entity_1.CommodityRefSchema).default([]),
    status: zod_1.z.enum(['pending', 'paid', 'refunded']).default('pending'),
});
