import { Button, Stack, TextField } from '@mui/material';
import { createWorkspace } from 'api/workspace';
import { useCurrentModal, useModal } from 'components/modal';
import React, { useState } from 'react';
import { WorkspaceCreate } from 'shared';

export function useCreateWorkspace() {
  const modal = useModal();

  return {
    showCreateWorkspaceModal() {
      modal.showModal('Create Workspace', <CreateWorkspaceModal />);
    },
  };
}

function CreateWorkspaceModal() {
  const modalCtx = useCurrentModal();
  const [workspaceInfo, setWorkspaceInfo] = useState<WorkspaceCreate>({
    name: '',
  });

  return (
    <Stack gap={2} overflow="visible">
      <TextField
        label="Workspace Name"
        value={workspaceInfo.name}
        onChange={(e) =>
          setWorkspaceInfo({ ...workspaceInfo, name: e.target.value })
        }
      />
      <Stack direction="row" justifyContent="flex-end" mb={-1}>
        <Button onClick={() => modalCtx?.closeModal()}>Cancel</Button>
        <Button
          onClick={async () => {
            await createWorkspace(workspaceInfo);
            // Create workspace
            modalCtx?.closeModal();
          }}
        >
          Create
        </Button>
      </Stack>
    </Stack>
  );
}
