import { Typography } from '@mui/material';
import EditableTextField from 'components/editableTextField';
import React from 'react';
import { FinsightEntityField } from 'shared';
import { formatName } from 'utils/fields';

export function StringRenderer({
  field,
  value,
  editable,
  onChange,
}: {
  field: FinsightEntityField;
  value: unknown;
  editable?: boolean;
  onChange?: (value: unknown) => Promise<void>;
}) {
  if (editable) {
    return (
      <EditableTextField
        notBlank={field.schema.required}
        variant="outlined"
        label={formatName(field.name ?? '')}
        value={value}
        onChange={async (v) => onChange?.(v)}
      />
    );
  }

  if (typeof value !== 'string') return <Typography>Invalid String</Typography>;

  return <Typography>{value}</Typography>;
}
