import { Typography } from '@mui/material';
import EditableTextField from 'components/editableTextField';
import React from 'react';
import { FinsightEntityField } from 'shared';
import { formatName } from 'utils/fields';

export function NumberRenderer({
  field,
  value,
  editable,
  readonly,
  onChange,
}: {
  field: FinsightEntityField;
  value: unknown;
  editable?: boolean;
  readonly?: boolean;
  onChange?: (value: unknown) => Promise<void>;
}) {
  if (editable) {
    return (
      <EditableTextField
        numeric
        readonly={readonly}
        notBlank={field.schema.required}
        variant="outlined"
        label={formatName(field.name ?? '')}
        value={value}
        InputProps={{
          startAdornment: field.schema.ui?.adornments?.start?.text ?? '',
        }}
        onChange={async (v) => {
          onChange?.(Number(v));
        }}
      />
    );
  }

  if (typeof value !== 'number') return <Typography>Invalid Number</Typography>;

  return (
    <Typography>
      {field.schema.ui?.adornments?.start?.text ?? ''}
      {value}
    </Typography>
  );
}
