"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskSchema = void 0;
var zod_1 = require("zod");
var entity_1 = require("./entity");
var project_1 = require("./project");
exports.TaskSchema = project_1.ProjectTaskSchema.extend({
    type: zod_1.z.literal('task'),
    parent: zod_1.z.union([entity_1.ProjectRefSchema, entity_1.TaskRefSchema, zod_1.z.null()]).default(null),
});
