"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserVerifySchema = exports.UserLoginSchema = void 0;
var zod_1 = require("zod");
exports.UserLoginSchema = zod_1.z.object({
    email: zod_1.z.string(),
    password: zod_1.z.string(),
});
exports.UserVerifySchema = zod_1.z.object({
    token: zod_1.z.string(),
});
