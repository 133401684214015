import { Button, Stack, TextField } from '@mui/material';
import { createScratchpadChannel } from 'api/scratchpad';
import { useCurrentModal, useModal } from 'components/modal';
import React, { useState } from 'react';

export function CreateScratchpadChannelModal() {
  const modalCtx = useCurrentModal();

  const [channelName, setChannelName] = useState('');

  return (
    <Stack gap={2} pt={2}>
      <TextField
        label="Channel Name"
        value={channelName}
        onChange={(e) => setChannelName(e.target.value)}
      />
      <Stack gap={1} direction="row" justifyContent="flex-end">
        <Button color="error" onClick={() => modalCtx?.closeModal()}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            try {
              await createScratchpadChannel({ name: channelName });
              modalCtx?.closeModal();
            } catch {}
          }}
        >
          Create
        </Button>
      </Stack>
    </Stack>
  );
}

export function useCreateScratchpadChannelModal() {
  const { showModal } = useModal();

  return {
    showCreateScratchpadChannelModal: () => {
      showModal(`New Scratchpad Channel`, <CreateScratchpadChannelModal />);
    },
  };
}
