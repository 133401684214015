import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  RemoveCircleOutline,
} from '@mui/icons-material';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import {
  ComputedFieldMetaSchema,
  FinsightEntityField,
  getEntityExtraFieldValue,
} from 'shared';
import { formatName } from 'utils/fields';

import { BooleanRenderer } from './boolean';
import { DateRenderer } from './date';
import { EntityRenderer } from './entity';
import { EnumRenderer } from './enum';
import { NumberRenderer } from './number';
import { StringRenderer } from './string';

export function FinsightEntityFieldValue({
  field,
  value: _value,
  viewType,
  editable,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
}: {
  field: FinsightEntityField;
  value: unknown;
  viewType: 'list' | 'card' | 'entity_main' | 'entity_sidebar';
  editable?: boolean;
  onChange?: (value: unknown) => Promise<void>;
}) {
  if (viewType === 'list' && !field.schema.ui?.visibility?.list) {
    return null;
  }

  if (viewType === 'card' && !field.schema.ui?.visibility?.card) {
    return null;
  }

  if (
    viewType === 'entity_main' &&
    (field.schema.ui?.visibility?.entity_main === false ||
      field.schema.ui?.visibility?.entity_sidebar === true)
  ) {
    return null;
  }

  if (
    viewType === 'entity_sidebar' &&
    (field.schema.ui?.visibility?.entity_sidebar !== true ||
      field.schema.ui?.visibility?.entity_main === true)
  ) {
    return null;
  }

  let value: unknown = null;

  try {
    value = getEntityExtraFieldValue(field, _value);
  } catch {
    if (editable) {
      value = null;
    } else {
      return <Typography>Invalid value</Typography>;
    }
  }

  return (
    <FinsightEntityFieldValueRenderer
      editable={editable}
      field={field}
      value={value}
      onChange={onChange}
    />
  );
}

function FinsightEntityFieldValueRenderer({
  field,
  value,
  editable,
  onChange,
  readonly,
}: {
  field: FinsightEntityField;
  value: unknown;
  editable?: boolean;
  readonly?: boolean;
  onChange?: (value: unknown) => Promise<void>;
}) {
  if (field.schema.multiple) {
    return (
      <Stack border="1px solid" borderColor="divider" p={2} borderRadius={1}>
        <Typography
          variant="caption"
          mt={-3.4}
          mb={1}
          ml={-1}
          bgcolor="background.default"
          width="fit-content"
          px={0.5}
          color="text.secondary"
        >
          {formatName(field.name)}
        </Typography>
        <Stack gap={1}>
          {Array.isArray(value) && !!value.length
            ? value.map((x, i) => (
                <Stack direction="row" key={i} alignItems="center" gap={1}>
                  <Typography minWidth="15px">{i + 1}.</Typography>
                  <Stack
                    sx={{
                      '& > *': {
                        margin: 0,
                        width: 24,
                        height: 24,
                      },
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (onChange && i > 0) {
                          const newValue = [...value];
                          const temp = newValue[i];
                          newValue[i] = newValue[i - 1];
                          newValue[i - 1] = temp;
                          onChange(newValue);
                        }
                      }}
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (onChange && i < value.length - 1) {
                          const newValue = [...value];
                          const temp = newValue[i];
                          newValue[i] = newValue[i + 1];
                          newValue[i + 1] = temp;
                          onChange(newValue);
                        }
                      }}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </Stack>
                  <Stack flexGrow={1}>
                    <FinsightEntityFieldValueRenderer
                      field={{
                        ...field,
                        schema: {
                          ...field.schema,
                          multiple: false,
                        },
                      }}
                      value={x}
                      editable={editable}
                      onChange={async (v) => {
                        if (onChange) {
                          const newValue = [...value];
                          newValue[i] = v;
                          await onChange(newValue);
                        }
                      }}
                    />
                  </Stack>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => {
                      if (onChange) {
                        const newValue = [...value];
                        newValue.splice(i, 1);
                        onChange(newValue);
                      }
                    }}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                </Stack>
              ))
            : 'No Items'}
        </Stack>
        <Divider>
          <Typography variant="caption" color="text.secondary">
            Add New
          </Typography>
        </Divider>
        <FinsightEntityFieldValueRenderer
          key={Math.random()}
          field={{
            ...field,
            schema: {
              ...field.schema,
              multiple: false,
            },
          }}
          value={null}
          editable={editable}
          onChange={async (v) => {
            if (onChange) {
              const valueArray = Array.isArray(value) ? value : [];
              const newValue = [...valueArray, v];
              await onChange(newValue);
            }
          }}
        />
      </Stack>
    );
  }

  if (field.schema.type === 'computed') {
    try {
      const metricMeta = ComputedFieldMetaSchema.parse(field.schema.meta);

      const metricType = metricMeta.type;

      return (
        <FinsightEntityFieldValueRenderer
          readonly
          value={value}
          editable={editable}
          field={{
            ...field,
            schema: {
              ...field.schema,
              type: metricType,
            },
          }}
        />
      );
    } catch {
      return <Typography>Invalid Metric schema</Typography>;
    }
  }

  if (field.schema.type === 'object') {
    if (
      typeof (value ?? null) !== 'object' ||
      !Array.isArray(field.schema.properties)
    ) {
      return <Typography>Invalid schema</Typography>;
    }

    const valueMap = (value ?? {}) as Record<string, unknown>;

    if (editable) {
      return (
        <Stack
          display="grid"
          gap={1}
          gridTemplateColumns="repeat(auto-fill, minmax(400px, 1fr))"
          justifyContent="stretch"
        >
          {field.schema.properties?.map((property, i) => {
            if (!property.name) {
              return (
                <Typography key={i}>
                  Unknown Property of type {property.type}
                </Typography>
              );
            }
            return (
              <FinsightEntityFieldValueRenderer
                field={{
                  name: property.name,
                  schema: property,
                  type: field.type,
                }}
                value={valueMap[property.name ?? '']}
                editable={editable}
                key={property.name}
                onChange={async (v) =>
                  onChange?.({
                    ...valueMap,
                    [property.name ?? '']: v,
                  })
                }
              />
            );
          })}
        </Stack>
      );
    }

    return (
      <Stack>
        Values:
        {field.schema.properties?.map((property) => {
          return (
            <Typography key={property.name}>
              {property.name}: {`${valueMap[property.name ?? '']}`}
            </Typography>
          );
        })}
      </Stack>
    );
  }

  if (field.schema.type === 'string') {
    return (
      <StringRenderer
        field={field}
        value={value}
        editable={editable}
        onChange={onChange}
      />
    );
  }

  if (field.schema.type === 'number') {
    return (
      <NumberRenderer
        field={field}
        value={value}
        readonly={readonly}
        editable={editable}
        onChange={onChange}
      />
    );
  }

  if (field.schema.type === 'boolean') {
    return (
      <BooleanRenderer
        field={field}
        value={value}
        editable={editable}
        onChange={onChange}
      />
    );
  }

  if (field.schema.type === 'date') {
    return (
      <DateRenderer
        editable={editable}
        field={field}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.schema.type === 'enum') {
    return (
      <EnumRenderer
        editable={editable}
        field={field}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.schema.type === 'entity') {
    return (
      <EntityRenderer
        editable={editable}
        field={field}
        value={value}
        onChange={onChange}
      />
    );
  }

  return (
    <Typography>
      {field.name}: {`${value}`}
    </Typography>
  );
}
