import React, { createContext, useContext, useState } from 'react';

type DragItem = {
  type: string;
  data: unknown;
};

type DnDContextType = {
  dragItem: DragItem | null;
  setDragItem: (item: DragItem | null) => void;
};

const DnDContext = createContext({} as DnDContextType);

export default function DndProvider(props: React.PropsWithChildren) {
  const [dragItem, setDragItem] = useState<DragItem | null>(null);

  const handleSetDragItem = (item: DragItem | null) => {
    setDragItem(item);
  };

  return (
    <DnDContext.Provider
      value={{
        dragItem,
        setDragItem: handleSetDragItem,
      }}
    >
      {props.children}
    </DnDContext.Provider>
  );
}

export function useDraggable() {
  const context = useContext(DnDContext);

  return context;
}
