import {
  Add,
  Apps,
  AutoAwesome,
  Delete,
  EmojiEmotions,
  Gif,
  Send,
  StickyNote2,
  Tag,
} from '@mui/icons-material';
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  createScratchpadMessage,
  deleteScratchpadMessage,
  useScratchpadChannelMessages,
  useScratchpadChannels,
  useScratchpadMessageDataMaidStatus,
} from 'api/scratchpad';
import { useCreateScratchpadChannelModal } from 'components/createScratchpadChannel';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function ScratchpadPage() {
  const params = useParams();
  const channelId = params.id;

  const navigate = useNavigate();

  const { data: scratchpadChannels } = useScratchpadChannels();
  const { data: scratchpadMessages } = useScratchpadChannelMessages(channelId);

  const { showCreateScratchpadChannelModal } =
    useCreateScratchpadChannelModal();

  useEffect(() => {
    if (!channelId && scratchpadChannels?.length) {
      navigate(`/scratchpad/${scratchpadChannels[0].id}`, {
        replace: true,
      });
    }
  });

  const currentChannel = useMemo(
    () => scratchpadChannels?.find((c) => c.id === channelId),
    [scratchpadChannels, channelId],
  );

  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);

  const sendMessage = async () => {
    if (!channelId || sending || !message.trim().length) return;

    setSending(true);
    try {
      await createScratchpadMessage({
        channel_id: channelId,
        content: message,
      });
    } catch {}
    setSending(false);
    setMessage('');
  };

  return (
    <Stack direction="row" mx={-3} height="100%">
      <Stack flex="0 0 250px" borderRight="1px solid" borderColor="divider">
        <List dense>
          {scratchpadChannels?.map((channel) => (
            <ListItemButton
              key={channel.id}
              selected={channel.id === channelId}
              href={`/scratchpad/${channel.id}`}
            >
              <ListItemIcon>
                <Tag />
              </ListItemIcon>
              <ListItemText primary={channel.name} />
            </ListItemButton>
          ))}
          <Divider />
          <ListItemButton
            dense
            onClick={() => showCreateScratchpadChannelModal()}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="Add channel" />
          </ListItemButton>
        </List>
      </Stack>
      <Stack flexGrow={1}>
        <Stack flexGrow={1} direction="column-reverse" overflow="auto">
          {scratchpadMessages?.length ? (
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
            >
              {scratchpadMessages?.map((message) => (
                <ListItem key={message.id}>
                  <ListItemAvatar>
                    <Avatar>NA</Avatar>
                  </ListItemAvatar>
                  <Stack>
                    <Stack gap={1} direction="row" alignItems="center">
                      <Typography variant="body2" fontWeight="bold">
                        Nasser Akhter
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        &mdash;
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {moment(message.created).fromNow()}
                      </Typography>
                      <ScratchpadDataMaidStatus messageId={message.id} />
                    </Stack>
                    <Typography whiteSpace="break-spaces">
                      {message.content}
                    </Typography>
                  </Stack>
                  <ListItemSecondaryAction>
                    <IconButton
                      color="error"
                      onClick={() =>
                        deleteScratchpadMessage(message.channel_id, message.id)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="caption" textAlign="center" p={2}>
              No messages
            </Typography>
          )}
        </Stack>
        <Stack
          direction="row"
          flexShrink={0}
          p={2}
          gap={1}
          borderTop="1px solid"
          borderColor="divider"
        >
          <IconButton disabled>
            <Add />
          </IconButton>
          <TextField
            multiline
            fullWidth
            size="small"
            disabled={sending}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                sendMessage();
              }
            }}
            placeholder={`Message #${currentChannel?.name}`}
          />
          <IconButton disabled={sending} onClick={() => sendMessage()}>
            <Send />
          </IconButton>
          <IconButton disabled>
            <Gif />
          </IconButton>
          <IconButton disabled>
            <StickyNote2 />
          </IconButton>
          <IconButton disabled>
            <EmojiEmotions />
          </IconButton>
          <IconButton disabled>
            <Apps />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

function ScratchpadDataMaidStatus(props: { messageId: string }) {
  const status = useScratchpadMessageDataMaidStatus(props.messageId);

  return (
    <IconButton
      size="small"
      sx={{
        color:
          status?.status === 'idle'
            ? 'gray'
            : status?.status === 'loading'
              ? 'pink'
              : status?.status === 'success'
                ? 'orange'
                : 'lightgray',
      }}
    >
      <AutoAwesome />
    </IconButton>
  );
}
