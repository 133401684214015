/* eslint-disable */

import moment, { Moment } from 'moment';
import { useContext } from 'react';

import {
  BudgetTemplate,
  CommodityTemplate,
  EntityTemplate,
  GlobalStore,
  MerchantTemplate,
  PriorityTemplate,
  ProjectTaskTemplate,
  TransactionCommodityTemplate,
  TransactionTemplate,
} from './globalStore';
import { ProjectsContext, WebAddress } from './provider';

// export class RelativePriority implements Priority {
//   value: number;
//   type: 'relative' = 'relative';
//   constructor(value: number) {
//     this.value = value;
//   }
// }

export const AbsolutePriorityLevelMeta = {
  lowest: {
    label: 'Lowest',
    value: 'lowest',
    numeric: 0,
  },
  low: {
    label: 'Low',
    value: 'low',
    numeric: 1,
  },
  medium: {
    label: 'Medium',
    value: 'medium',
    numeric: 2,
  },
  high: {
    label: 'High',
    value: 'high',
    numeric: 3,
  },
  highest: {
    label: 'Highest',
    value: 'highest',
    numeric: 4,
  },
} as const;

export type AbsolutePriorityLevel = keyof typeof AbsolutePriorityLevelMeta;

export class Priority {
  parent?: Entity;
  prerequisites: Entity[];

  constructor(level: AbsolutePriorityLevel, prerequisites: Entity[] = []) {
    this._level = level;
    this.prerequisites = prerequisites;
  }

  private _level: AbsolutePriorityLevel;

  public get level(): AbsolutePriorityLevel {
    return this._level;
  }
  public set level(value: AbsolutePriorityLevel) {
    this._level = value;
    this.parent?.events.entityUpdated.forEach((event) => event());
  }

  static import(priority: PriorityTemplate) {
    const priorityInstance = new Priority(priority.level);

    return priorityInstance;
  }

  export(): PriorityTemplate {
    return {
      type: 'absolute',
      level: this.level,
    };
  }

  compare(priority: Priority, entityId: string) {
    if (
      this.prerequisites.length &&
      priority.prerequisites.length &&
      this.prerequisites.some(() => entityId)
    ) {
      return 1;
    } else {
      return AbsolutePriorityLevelMeta[priority.level].numeric >
        AbsolutePriorityLevelMeta[this.level].numeric
        ? 1
        : -1;
    }
  }
}

// ==-=-=-
class SecureContent {
  publicKey: string;

  private cache?: Buffer;
  private encrypted: Buffer;
  private _decryptTimeout: number;

  get decryptTimeout() {
    return this._decryptTimeout;
  }

  constructor(
    content: Buffer,
    publicKey: string,
    decryptTimeout: number = 5000,
  ) {
    this.publicKey = publicKey;
    this.encrypted = content;
    this._decryptTimeout = decryptTimeout;
  }
}

interface IBudgetSource {
  id: string;
  name: string;
  amount: number;
}

export class BankCardBudgetSource implements IBudgetSource {
  id: string;
  name: string;
  bank: string;
  amount: number;
  details?: SecureContent;

  constructor(name: string, amount: number, bank: string) {
    this.id = window.crypto.randomUUID();
    this.name = name;
    this.amount = amount;
    this.bank = bank;
  }
}

export class GiftCardBudgetSource implements IBudgetSource {
  id: string;
  name: string;
  amount: number;
  merchant: string;
  details?: SecureContent;

  constructor(name: string, amount: number, merchant: string) {
    this.id = window.crypto.randomUUID();
    this.name = name;
    this.amount = amount;
    this.merchant = merchant;
  }
}

type BudgetSource = BankCardBudgetSource | GiftCardBudgetSource;

export class Budget {
  private _parent?: Entity;
  private _estimate: MonetaryValue;
  private _limit: null | MonetaryValue;
  transactions: Transaction[] = [];

  public get estimate(): MonetaryValue {
    return this._estimate;
  }

  public set estimate(value: MonetaryValue) {
    this._estimate = value;
    this.parent?.events.entityUpdated.forEach((event) => event());
  }

  public get limit(): null | MonetaryValue {
    return this._limit;
  }

  public set limit(value: null | MonetaryValue) {
    this._limit = value;
    this.parent?.events.entityUpdated.forEach((event) => event());
  }

  get parent(): Entity | undefined {
    return this._parent;
  }

  set parent(parent: Entity | undefined) {
    this._parent = parent;
    for (const transaction of this.transactions) {
      transaction.parent = parent;
    }
  }

  metrics: {
    total: MonetaryValue;
  } = {
    total: new MonetaryValue(0),
  };

  constructor(estimate: MonetaryValue, limit: null | MonetaryValue) {
    this._limit = limit;
    this._estimate = estimate;
  }

  static import(budget: BudgetTemplate) {
    const budgetInstance = new Budget(
      new MonetaryValue(budget.estimate),
      budget.limit ? new MonetaryValue(budget.limit) : null,
    );
    if (budget.transactions) {
      for (const transaction of budget.transactions) {
        const transactionInstance = Transaction.import(transaction);
        budgetInstance.addTransaction(transactionInstance);
      }
    }

    return budgetInstance;
  }

  export(): BudgetTemplate {
    const transactions = this.transactions.map((transaction) =>
      transaction.export(),
    );

    return {
      limit: this.limit?.rawValue ?? null,
      estimate: this.estimate.rawValue,
      transactions,
    };
  }

  private _addTransaction(transaction: Transaction) {
    transaction.parent = this.parent;
    transaction.budget = this;
    this.transactions.push(transaction);
  }

  addTransaction(transaction: Transaction) {
    this._addTransaction(transaction);
    // this.calculateMetrics();
    this.parent?.resolveReferences();
  }
  addTransactions(transactions: Transaction[]) {
    for (const transaction of transactions) {
      this._addTransaction(transaction);
    }
    // this.calculateMetrics();
    this.parent?.resolveReferences();
  }

  calculateMetrics() {
    this.transactions.sort((a, b) => a.date.diff(b.date));

    this.metrics = {
      total: new MonetaryValue(0),
    };

    // for (const transaction of this.transactions) {
    //   this.metrics.total += transaction.amount;
    // }

    // TODO: FIX CACHE TO AGG_TRANSACTIONS
    for (const transaction of this.parent?.cache.agg_transactions ?? []) {
      this.metrics.total.rawValue += transaction.amount.rawValue;
    }

    this.metrics.total.rawValue =
      Math.round(this.metrics.total.rawValue * 100) / 100;

    if (this.metrics.total.rawValue !== 0) {
      this.metrics.total.rawValue *= -1;
    }
  }
}

export class Address {
  id: string;
  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  zip: string;
  country: string;

  constructor(
    streetNumber: string,
    streetName: string,
    city: string,
    state: string,
    zip: string,
    country: string,
  ) {
    this.id = window.crypto.randomUUID();
    this.streetNumber = streetNumber;
    this.streetName = streetName;
    this.city = city;
    this.state = state;
    this.zip = zip;
    this.country = country;
  }
}

export class Merchant {
  name: string;
  logoUrl?: string;
  website: string;
  description: string;
  address?: Address;
  transactions: Transaction[] = [];
  private _id: string;

  static import(merchant: MerchantTemplate) {
    const merchantInstance = new Merchant(
      merchant.name,
      new WebAddress(merchant.url).toString(),
      merchant.description,
      merchant.faviconLogo,
    );

    merchantInstance.id = merchant.id;

    if (merchant.logoUrl) {
      merchantInstance.logoUrl = merchant.logoUrl;
    }

    return merchantInstance;
  }

  export(): MerchantTemplate {
    return {
      id: this.id,
      description: this.description,
      name: this.name,
      url: this.website,
      logoUrl: this.logoUrl,
    };
  }

  constructor(
    name: string,
    website: string,
    description: string,
    favicon?: boolean,
  ) {
    this._id = window.crypto.randomUUID();
    this.name = name;
    this.website = website;
    this.description = description;
    if (favicon) {
      this.logoUrl = `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${website}&size=64`;
    } else {
      this.logoUrl = `https://logo.clearbit.com/${website}`;
    }

    GlobalStore.registerMerchant(this);
  }

  get id() {
    return this._id;
  }

  set id(id: string) {
    this.events.idUpdated.forEach((event) => event(this._id, id));
    this._id = id;
  }

  addTransaction(transaction: Transaction) {
    transaction.merchant = this;
    this.transactions.push(transaction);
  }

  events = {
    idUpdated: [] as ((oldId: string, newId: string) => void)[],
  };
}

export class Transaction {
  id: string;
  name: string;
  amount: MonetaryValue;
  date: Moment;
  budget?: Budget;
  status: 'pending' | 'paid' | 'refunded' = 'paid';
  merchant?: Merchant;
  sources: BudgetSource[] = [];
  commodities: TransactionCommodity[] = [];
  private _parent?: Entity;
  private _parentPaths: string[] = [];

  static import(transaction: TransactionTemplate) {
    const transactionInstance = new Transaction(
      transaction.name,
      new MonetaryValue(transaction.amount),
      moment(transaction.date),
    );

    transactionInstance.id = transaction.id;

    if (transaction.merchant) {
      const merchantInstance = GlobalStore.getMerchantById(
        transaction.merchant,
      );

      if (merchantInstance) {
        transactionInstance.merchant = merchantInstance;
      }
    }

    // if (transaction.sources) {
    //   transactionInstance.sources = transaction.sources;
    // }

    if (transaction.commodities) {
      for (const commodity of transaction.commodities) {
        const commodityInstance = GlobalStore.getCommodityById(
          commodity.commodity,
        );

        if (commodityInstance) {
          transactionInstance.addCommodity(
            new TransactionCommodity(commodityInstance, commodity.quantity),
          );
        }
      }
    }

    return transactionInstance;
  }

  export(): TransactionTemplate {
    return {
      id: this.id,
      amount: this.amount.rawValue,
      date: this.date.toString(),
      name: this.name,
      commodities: this.commodities.map((commodity) => commodity.export()),
      merchant: this.merchant?.id,
      sources: [],
    };
  }

  constructor(name: string, amount: MonetaryValue, date?: Moment) {
    this.id = window.crypto.randomUUID();
    this.name = name;
    this.amount = amount;
    this.date = date ?? moment();
  }

  addCommodity(commodity: TransactionCommodity) {
    this.commodities.push(commodity);
    commodity.commodity.addTransaction(this);
  }

  get parentPaths() {
    return this._parentPaths ?? [];
  }

  get parent() {
    return this._parent;
  }

  set parent(parent: Entity | undefined) {
    this._parent = parent;
    this._events.parentUpdated();
  }

  _events = {
    parentUpdated: () => {
      if (this._parent) {
        this._parentPaths = [...this._parent.parentPaths, this._parent?.name];
      }
    },
  };
}

export class TransactionCommodity {
  commodity: Commodity;
  quantity: number;

  export(): TransactionCommodityTemplate {
    return {
      commodity: this.commodity.id,
      quantity: this.quantity,
    };
  }

  constructor(commodity: Commodity, quantity: number) {
    this.commodity = commodity;
    this.quantity = quantity;
  }
}

// ==--=-=-==-=

export abstract class Entity {
  name: string;
  priority: Priority;
  created: Moment = moment();
  type: 'project' | 'task' | 'commodity';
  status: 'planning' | 'ready' | 'active' | 'completed' | 'cancelled' =
    'planning';
  children: Entity[] = [];
  description: string;
  deadline?: Moment;
  private _parent?: Entity;
  private _budget?: Budget;
  private _parentPaths: string[] = [];
  private _id: string;

  static import(entity: EntityTemplate): Entity {
    switch (entity.type) {
      case 'project':
        return Project.import(entity);
      case 'task':
        return Task.import(entity);
      case 'commodity':
        return Commodity.import(entity);
    }
  }

  export(): EntityTemplate {
    if (this.type === 'commodity') throw new Error();

    const template: ProjectTaskTemplate = {
      children: this.children.map((child) => child.export()),
      deadline: this.deadline?.toString(),
      description: this.description,
      id: this.id,
      name: this.name,
      priority: this.priority.export(),
      type: this.type,
      budget: this.budget?.export(),
    };

    return template;
  }

  constructor(
    type: 'project' | 'task' | 'commodity',
    name: string,
    priority: Priority,
    description: string,
  ) {
    this.type = type;
    this._id = window.crypto.randomUUID();
    this.name = name;
    this.priority = priority;
    this.description = description;

    priority.parent = this;

    GlobalStore.registerEntity(this);
  }

  get id() {
    return this._id;
  }

  set id(id: string) {
    this.events.idUpdated.forEach((event) => event(this._id, id));
    this._id = id;
  }

  get parent() {
    return this._parent;
  }

  set parent(parent: Entity | undefined) {
    this.events.parentUpdated.forEach((event) => event(this._parent, parent));
    this._parent = parent;
    this._events.parentUpdated();
    for (const child of this.children) {
      child._events.parentUpdated();
    }

    for (const transaction of this.budget?.transactions ?? []) {
      transaction._events.parentUpdated();
    }
  }

  // events that will be called by anything other than this class
  _events = {
    parentUpdated: () => {
      if (this._parent) {
        this._parentPaths = [...this._parent.parentPaths, this._parent?.name];
      }
    },
  };

  // events that will be called by this class
  events = {
    idUpdated: [] as ((oldId: string, newId: string) => void)[],
    parentUpdated: [] as ((
      oldParent: Entity | undefined,
      newParent: Entity | undefined,
    ) => void)[],
    entityUpdated: [] as (() => void)[],
  };

  get parentPaths() {
    return this._parentPaths ?? [];
  }

  get budget(): Budget | undefined {
    return this._budget;
  }

  addBudget(budget: Budget) {
    this._budget = budget;
    budget.parent = this;
    this.resolveReferences();
  }

  // Children
  private _addChild(entity: Entity) {
    this.children.push(entity);
    entity.parent = this;
  }

  addChild(entity: Entity) {
    this._addChild(entity);
    this.resolveReferences();
  }

  addChildren(entities: Entity[]) {
    for (const entity of entities) {
      this._addChild(entity);
    }
    this.resolveReferences();
  }

  // Dynamic Updating

  resolveReferences() {
    this.children.sort((a, b) => a.priority.compare(b.priority, b.id));

    this.cache.tasks = this.children.filter(
      (child) => child.type === 'task',
    ) as Task[];

    this.cache.projects = this.children.filter(
      (child) => child.type === 'project',
    ) as Project[];

    this.cache.commodities = this.children.filter(
      (child) => child.type === 'commodity',
    ) as Commodity[];

    this.cache.agg_children = this._recurseChildren(this.children);

    this.cache.agg_tasks = this.cache.agg_children.filter(
      (child) => child.type === 'task',
    ) as Task[];

    this.cache.agg_projects = this.cache.agg_children.filter(
      (child) => child.type === 'project',
    ) as Project[];

    this.cache.agg_commodities = this.cache.agg_children.filter(
      (child) => child.type === 'commodity',
    ) as Commodity[];

    this.cache.agg_transactions = [
      ...(this.budget?.transactions ?? []),
      ...this.cache.agg_children
        .map((entity) => (entity.budget ? entity.budget.transactions : []))
        .flat(),
    ].sort((a, b) => a.date.diff(b.date));

    this.budget?.calculateMetrics();

    this.parent?.resolveReferences();
  }

  private _recurseChildren(entities: Entity[]) {
    let children: Entity[] = [];

    for (const entity of entities) {
      children.push(entity);
      children.push(...this._recurseChildren(entity.children));
    }

    return children;
  }

  // Cache

  cache = {
    tasks: [] as Task[],
    projects: [] as Project[],
    commodities: [] as Commodity[],
    agg_children: [] as Entity[],
    agg_tasks: [] as Task[],
    agg_projects: [] as Project[],
    agg_transactions: [] as Transaction[],
    agg_commodities: [] as Commodity[],
  };

  get tasks() {
    return this.cache.tasks;
  }

  get projects() {
    return this.cache.projects;
  }

  get agg_tasks() {
    return this.cache.agg_tasks;
  }

  get agg_projects() {
    return this.cache.agg_projects;
  }

  get agg_commodities() {
    return this.cache.agg_commodities;
  }

  get agg_transactions() {
    return this.cache.agg_transactions;
  }
}

export class Project extends Entity {
  constructor(name: string, priority: Priority, description: string) {
    super('project', name, priority, description);
  }

  static import(entity: ProjectTaskTemplate): Project {
    if (entity.type !== 'project') throw new Error();

    const priorityInstance = Priority.import(entity.priority);

    const projectInstance = new Project(
      entity.name,
      priorityInstance,
      entity.description,
    );

    projectInstance.id = entity.id;

    if (entity.deadline) {
      projectInstance.deadline = moment(entity.deadline);
    }

    if (entity.children) {
      for (const child of entity.children) {
        const entity = Entity.import(child);
        projectInstance.addChild(entity);
      }
    }

    if (entity.budget) {
      const budgetInstance = Budget.import(entity.budget);
      projectInstance.addBudget(budgetInstance);
    }

    return projectInstance;
  }
}

export class Task extends Entity {
  static import(entity: ProjectTaskTemplate): Task {
    if (entity.type !== 'task') throw new Error();
    entity.deadline;
    entity.children;
    entity.budget;

    const priorityInstance = Priority.import(entity.priority);

    const taskInstance = new Task(
      entity.name,
      priorityInstance,
      entity.description,
    );

    taskInstance.id = entity.id;

    if (entity.deadline) {
      taskInstance.deadline = moment(entity.deadline);
    }

    if (entity.children) {
      for (const child of entity.children) {
        const entity = Entity.import(child);
        taskInstance.addChild(entity);
      }
    }

    if (entity.budget) {
      const budgetInstance = Budget.import(entity.budget);
      taskInstance.addBudget(budgetInstance);
    }

    return taskInstance;
  }

  constructor(name: string, priority: Priority, description: string) {
    super('task', name, priority, description);
  }
}

export class Commodity extends Entity {
  brand: string;
  price: MonetaryValue;
  unit?: string;
  // merchant?: Merchant;
  children: Entity[] = [];
  transactions: Transaction[] = [];
  imageUrl?: string;
  private _quantity: number = 0;

  static import(entity: CommodityTemplate): Commodity {
    if (entity.type !== 'commodity') throw new Error();

    const commodityInstance = new Commodity(
      entity.name,
      entity.brand,
      new MonetaryValue(entity.price),
    );

    commodityInstance.id = entity.id;

    // if (entity.merchant) {
    //   const merchantInstance = GlobalStore.getMerchantById(entity.merchant);
    //   commodityInstance.merchant = merchantInstance;
    // }

    commodityInstance.unit = entity.unit;

    commodityInstance.imageUrl = entity.imageUrl;

    return commodityInstance;
  }

  export(): CommodityTemplate {
    return {
      brand: this.brand,
      id: this.id,
      name: this.name,
      price: this.price.rawValue,
      type: 'commodity',
      imageUrl: this.imageUrl,
      unit: this.unit,
    };
  }

  constructor(name: string, brand: string, price: MonetaryValue) {
    super('commodity', name, new Priority('medium'), '');
    this.brand = brand;
    this.price = price;
  }

  addTransaction(transaction: Transaction) {
    this.transactions.push(transaction);
    this.resolveReferences();
  }

  resolveReferences() {
    super.resolveReferences();

    this._quantity = 0;
    for (const transaction of this.transactions) {
      const transactionCommodity = transaction.commodities.find(
        (commodity) => commodity.commodity.id === this.id,
      );

      if (transactionCommodity) {
        this._quantity += transactionCommodity.quantity;
      }
    }
  }

  get quantity() {
    return this._quantity;
  }
}

export class MonetaryValue {
  rawValue: number;
  private converter: Intl.NumberFormat;

  constructor(value: number, converter?: Intl.NumberFormat) {
    this.rawValue = value;

    if (converter) {
      this.converter = converter;
    } else {
      this.converter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }
  }

  rawAdd(value: number, update: boolean = false) {
    if (update) {
      this.rawValue += value;
      return this;
    } else {
      return new MonetaryValue(this.rawValue + value, this.converter);
    }
  }

  rawSubtract(value: number, update: boolean = false) {
    if (update) {
      this.rawValue -= value;
      return this;
    } else {
      return new MonetaryValue(this.rawValue - value, this.converter);
    }
  }

  rawMultiply(value: number, update: boolean = false) {
    if (update) {
      this.rawValue *= value;
      return this;
    } else {
      return new MonetaryValue(this.rawValue * value, this.converter);
    }
  }

  negate(update: boolean = false) {
    if (update) {
      this.rawValue *= -1;
      return this;
    } else {
      return new MonetaryValue(this.rawValue * -1, this.converter);
    }
  }

  toString() {
    return this.converter.format(this.rawValue);
  }
}

export function useProjects() {
  const projectContext = useContext(ProjectsContext);
  return projectContext.entities;
}
