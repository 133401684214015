import { EndpointUrl } from 'api/endpoint';
import { useCurrentUser } from 'api/providers/authProvider';
import { useWorkspaceId } from 'api/providers/workspaceProvider';
import {
  FinsightEntity,
  FinsightEntityCreateRequest,
  FinsightEntityCreateRequestSchema,
  FinsightEntityField,
  FinsightEntityUpdateRequest,
  FinsightEntityUpdateRequestSchema,
} from 'shared';
import useSWR, { mutate } from 'swr';

function entityFetcher<T = FinsightEntity[]>(token: string | null | undefined) {
  if (!token) return null;
  return async (url: string) => {
    const response = await fetch(url, {
      headers: {
        'x-finsight-token': token,
      },
    });
    if (!response.ok) return null;
    const data = await response.json();
    return data as T;
  };
}

export function useEntityFetcher<T = FinsightEntity[]>(url: string | null) {
  const user = useCurrentUser();
  const workspaceId = useWorkspaceId();

  return useSWR(
    EndpointUrl && user && url && workspaceId
      ? `${EndpointUrl}/workspace/${workspaceId}/${url}`
      : null,
    entityFetcher<T>(user?.token),
  );
}

export function useAllEntities(type: string) {
  return useEntityFetcher(`entities/${type}/all`);
}

export function useRootEntities(type: string) {
  return useEntityFetcher(`entities/${type}/root`);
}

export function useRecentEntities(type: string) {
  return useEntityFetcher(`entities/${type}/recent`);
}

export function useEntity(id: string | undefined) {
  return useEntityFetcher<FinsightEntity | null>(`entity/${id}`);
}

export function useEntityChildren(id: string | undefined, all = true) {
  return useEntityFetcher(id ? `entity/${id}/children?all=${all}` : null);
}

export function useEntityExtraFields(type?: string) {
  return useEntityFetcher<FinsightEntityField[]>(
    type ? `entities/${type}/fields` : null,
  );
}

export function useEntityActions() {
  const workspaceId = useWorkspaceId();

  return {
    createEntity: async function (entityData: FinsightEntityCreateRequest) {
      if (!workspaceId) throw new Error('No workspace selected');
      const payload = FinsightEntityCreateRequestSchema.parse(entityData);
      return await fetch(`${EndpointUrl}/workspace/${workspaceId}/entity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-finsight-token': localStorage.getItem('token') || '',
        },
        body: JSON.stringify(payload),
      });
    },
    deleteEntity: async function (id: string) {
      if (!workspaceId) throw new Error('No workspace selected');
      return await fetch(
        `${EndpointUrl}/workspace/${workspaceId}/entity/${id}`,
        {
          method: 'DELETE',
          headers: {
            'x-finsight-token': localStorage.getItem('token') || '',
          },
        },
      );
    },
    updateEntity: async function (
      id: string,
      entityData: FinsightEntityUpdateRequest,
    ) {
      if (!workspaceId) throw new Error('No workspace selected');
      const payload = FinsightEntityUpdateRequestSchema.parse(entityData);
      const response = await fetch(
        `${EndpointUrl}/workspace/${workspaceId}/entity/${id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-finsight-token': localStorage.getItem('token') || '',
          },
          body: JSON.stringify(payload),
        },
      );

      mutate(`${EndpointUrl}/workspace/${workspaceId}/entity/${id}`);

      return response;
    },
  };
}
