import { AccountCircleOutlined, Menu } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Container,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Sidebar from 'components/sidebar';
import { useIsTablet } from 'hooks/useIsMobile';
import React, { useState } from 'react';

export default function Header(props: React.PropsWithChildren) {
  const isMobile = useIsTablet();

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: 1300 }}>
        <Toolbar sx={{ minHeight: '48px !important' }}>
          <Container maxWidth="lg">
            <Stack
              height="100%"
              flexGrow={1}
              direction="row"
              alignItems="stretch"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="centers">
                {isMobile && (
                  <Stack mr={1}>
                    <IconButton onClick={() => setDrawerOpen(true)}>
                      <Menu />
                    </IconButton>
                  </Stack>
                )}
                <Button href="/">
                  <Typography
                    variant="h5"
                    fontWeight={500}
                    color="primary.contrastText"
                    textTransform="uppercase"
                  >
                    Finsight
                  </Typography>
                  <Typography color="primary.contrastText" ml={1}>
                    by Microart
                  </Typography>
                </Button>
              </Stack>
              {!isMobile && (
                <TextField
                  placeholder="Search"
                  sx={{
                    flex: '0 1 400px',
                    '.MuiInputBase-root': {
                      height: '100%',
                    },
                    input: {
                      height: '100%',
                      boxSizing: 'border-box',
                    },
                  }}
                />
              )}
              <Stack direction="row" alignItems="center">
                <IconButton>
                  <AccountCircleOutlined />
                </IconButton>
              </Stack>
            </Stack>
          </Container>
        </Toolbar>
      </AppBar>
      <Stack height="100%">
        <Stack direction="row" flexShrink={1} flexGrow={1} minHeight={0}>
          <Sidebar open={drawerOpen} onClose={() => setDrawerOpen(false)} />
          <Stack flexGrow={1} height="100%" overflow="auto" px={3}>
            <Toolbar />
            {props.children}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
