import { Button, Stack, Typography } from '@mui/material';
import { useCurrentModal, useModal } from 'components/modal';
import React from 'react';

export default function useConfirm() {
  const modal = useModal();

  return {
    async confirm(message?: string, title?: string) {
      return new Promise((resolve) => {
        modal.showModal(
          title ?? 'Are you sure?',
          <ConfirmModal
            onConfirm={() => {
              resolve(true);
            }}
          >
            {message ?? 'Are you sure you want to delete this entity?'}
          </ConfirmModal>,
          undefined,
          {},
          () => {
            resolve(false);
          },
        );
      });
    },
  };
}

function ConfirmModal(props: {
  children: React.ReactNode;
  onConfirm: () => void;
}) {
  const modalCtx = useCurrentModal();
  return (
    <Stack gap={1}>
      <Typography>{props.children}</Typography>
      <Stack mb={-1} direction="row" justifyContent="flex-end">
        <Button onClick={() => modalCtx?.closeModal()}>Cancel</Button>
        <Button
          color="error"
          onClick={() => {
            props.onConfirm();
            modalCtx?.closeModal();
          }}
        >
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
}
