"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntitySchema = exports.BaseEntitySchema = exports.MongoIdSchema = exports.DateSchema = exports.TransactionRefSchema = exports.CommodityRefSchema = exports.TaskRefSchema = exports.ProjectRefSchema = exports.PriorityNames = exports.PrioritySchema = exports.RefSchema = void 0;
var uuid_1 = require("uuid");
var zod_1 = require("zod");
exports.RefSchema = zod_1.z.object({
    id: zod_1.z.string(),
    type: zod_1.z.enum(['project', 'task', 'commodity', 'transaction']),
});
exports.PrioritySchema = zod_1.z.object({
    level: zod_1.z.number().int().min(0).max(5),
    prerequisites: zod_1.z.array(exports.RefSchema),
});
exports.PriorityNames = [
    'lowest',
    'low',
    'medium',
    'high',
    'highest',
    'immediate',
];
exports.ProjectRefSchema = exports.RefSchema.extend({
    type: zod_1.z.literal('project'),
});
exports.TaskRefSchema = exports.RefSchema.extend({
    type: zod_1.z.literal('task'),
});
exports.CommodityRefSchema = exports.RefSchema.extend({
    type: zod_1.z.literal('commodity'),
    id: zod_1.z.string(),
    quantity: zod_1.z.union([zod_1.z.number().int().default(0), zod_1.z.null()]).default(null),
});
exports.TransactionRefSchema = exports.RefSchema.extend({
    type: zod_1.z.literal('transaction'),
});
// // Only works for in memory moment objects, NOT JSON
// export const MomentSchema = z.custom<Moment>(
//   (value) => moment.isMoment(value) && 'isValid' in value && value.isValid(),
// );
// export const DateSchema = z.preprocess(
//   (v, ctx) => {
//     // only two types are accepted:
//     // string and moment
//     // if it's a string, it should be convertered to a moment
//     // if it's a moment, it should be conveted to string
//     if (typeof v === 'string') {
//       return moment(v);
//     } else if (moment.isMoment(v) && 'isValid' in v && v.isValid()) {
//       return v.toISOString();
//     }
//     ctx.addIssue({
//       code: 'invalid_type',
//       expected: 'string',
//       received: typeof v,
//     });
//     ctx.addIssue({
//       code: 'invalid_type',
//       expected: 'date',
//       received: typeof v,
//       message: 'Expected moment',
//     });
//   },
//   z.union([z.string(), MomentSchema]),
// );
exports.DateSchema = zod_1.z.coerce.date();
exports.MongoIdSchema = zod_1.z.preprocess(function (value) { return "".concat(value); }, zod_1.z.string());
exports.BaseEntitySchema = zod_1.z.object({
    _id: exports.MongoIdSchema.default(function () { return (0, uuid_1.v4)(); }),
    type: zod_1.z.enum(['project', 'task', 'commodity']),
    name: zod_1.z.string(),
    description: zod_1.z.string(),
    parentPaths: zod_1.z.array(zod_1.z.string()).default([]),
    parent: zod_1.z.union([exports.ProjectRefSchema, zod_1.z.null()]).default(null),
    created: exports.DateSchema,
    children: zod_1.z.array(exports.RefSchema).default([]),
});
exports.EntitySchema = exports.BaseEntitySchema.extend({
    priority: exports.PrioritySchema.default({
        level: 2,
        prerequisites: [],
    }),
    tasks: zod_1.z.array(exports.TaskRefSchema).default([]),
    transactions: zod_1.z.array(exports.TransactionRefSchema).default([]),
});
