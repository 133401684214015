"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComputedFieldMetaSchema = exports.FieldSchemaSchema = exports.SchemaConstraintsSchema = void 0;
var zod_1 = require("zod");
var SchemaUiVisibilitySchema = zod_1.z.object({
    card: zod_1.z.boolean().optional(),
    list: zod_1.z.boolean().optional(),
    entity_main: zod_1.z.boolean().optional(),
    entity_sidebar: zod_1.z.boolean().optional(),
});
var SchemaUiAdornmentObjSchema = zod_1.z.object({
    text: zod_1.z.string().optional(),
    icon: zod_1.z.string().optional(),
    color: zod_1.z.string().optional(),
});
var SchemaUiAdornmentSchema = zod_1.z.object({
    start: SchemaUiAdornmentObjSchema.optional(),
    end: SchemaUiAdornmentObjSchema.optional(),
});
var SchemaUiSchema = zod_1.z.object({
    visibility: SchemaUiVisibilitySchema.optional(),
    adornments: SchemaUiAdornmentSchema.optional(),
});
exports.SchemaConstraintsSchema = zod_1.z.object({
    name: zod_1.z.string(),
    property: zod_1.z.string(),
    operator: zod_1.z.enum(['equals', 'not_equals', 'in', 'not_in']),
    value: zod_1.z.union([
        zod_1.z.union([zod_1.z.string(), zod_1.z.number(), zod_1.z.boolean()]),
        zod_1.z.union([zod_1.z.string(), zod_1.z.number(), zod_1.z.boolean()]).array(),
    ]),
});
exports.FieldSchemaSchema = zod_1.z.object({
    type: zod_1.z.enum([
        'string',
        'number',
        'boolean',
        'object',
        'date',
        'enum',
        'entity',
        'computed',
    ]),
    required: zod_1.z.boolean().optional(),
    name: zod_1.z.string().optional(),
    properties: zod_1.z
        .union([
        zod_1.z.record(zod_1.z.lazy(function () { return exports.FieldSchemaSchema; })),
        zod_1.z.array(zod_1.z.lazy(function () { return exports.FieldSchemaSchema; })),
    ])
        .optional(),
    ui: SchemaUiSchema.optional(),
    sortable: zod_1.z.boolean().optional(),
    groupable: zod_1.z.boolean().optional(),
    multiple: zod_1.z.boolean().optional(),
    default: zod_1.z.union([zod_1.z.string(), zod_1.z.number(), zod_1.z.boolean()]).optional(),
    order: zod_1.z.number().optional(),
    constraints: exports.SchemaConstraintsSchema.array().optional(),
    meta: zod_1.z.record(zod_1.z.unknown()).optional(),
});
// metric filter is ALWAYS children
// but 'children' just means ALL and
// schemaConstraints allows for more complex filters
exports.ComputedFieldMetaSchema = zod_1.z.object({
    type: zod_1.z.enum(['number']),
    function: zod_1.z.enum(['sum']),
    filter: zod_1.z.union([zod_1.z.literal('children'), exports.SchemaConstraintsSchema.array()]),
    dependencies: zod_1.z.array(zod_1.z.string()).min(1),
});
