import { Autocomplete, TextField, Typography } from '@mui/material';
import { EndpointUrl } from 'api/endpoint';
import { useWorkspaceId } from 'api/providers/workspaceProvider';
import React, { useEffect, useRef, useState } from 'react';
import { FinsightEntity, FinsightEntityField } from 'shared';
import { formatName } from 'utils/fields';

export function EntityRenderer({
  field,
  value: _value,
  editable,
  onChange,
}: {
  field: FinsightEntityField;
  value: unknown;
  editable?: boolean;
  onChange?: (value: unknown) => Promise<void>;
}) {
  const workspaceId = useWorkspaceId();
  const value = _value ?? undefined;

  const [open, setOpen] = useState(false);
  const fetched = useRef(false);
  const initFetched = useRef(false);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<Record<string, FinsightEntity>>({});

  useEffect(() => {
    if (
      !initFetched.current &&
      value !== undefined &&
      typeof value === 'string'
    ) {
      initFetched.current = true;
      fetch(
        `${EndpointUrl}/workspace/${workspaceId}/entity/${value}?withFields=false`,
        {
          headers: {
            'x-finsight-token': localStorage.getItem('token') || '',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setOptions({ [value]: data });
          }
        });
    }
  }, [value, workspaceId]);

  useEffect(() => {
    if (!fetched.current && loading && open) {
      fetched.current = true;
      // fetch data
      fetch(
        `${EndpointUrl}/workspace/${workspaceId}/entities/all?withFields=false`,
        {
          method: 'POST',
          headers: {
            'x-finsight-token': localStorage.getItem('token') || '',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(field.schema.constraints ?? []),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            const map: Record<string, FinsightEntity> = {};

            for (const _entity of data) {
              const entity = _entity as FinsightEntity;
              map[entity.id] = entity;
            }

            setOptions(map);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [field.schema.constraints, loading, open, workspaceId]);

  if (value !== undefined && typeof value !== 'string') {
    return <Typography>Invalid String</Typography>;
  }

  if (editable) {
    return (
      <Autocomplete
        fullWidth
        value={value}
        loading={loading}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={Object.keys(options)}
        getOptionLabel={(option) => options[option]?.name ?? option}
        onChange={(_, v) => onChange?.(v)}
        renderInput={(params) => (
          <TextField {...params} label={formatName(field.name)} />
        )}
      />
    );
  }

  return <Typography>{value}</Typography>;
}
