import { EndpointUrl } from 'api/endpoint';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from 'shared';
import useSWRImmutable from 'swr/immutable';

type AuthProviderContextType = {
  user?: User;
};

const AuthProviderContext = createContext({} as AuthProviderContextType);

export default function AuthProvider(props: React.PropsWithChildren) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useCurrentUserApiRequest();

  useEffect(() => {
    if (user.error && location.pathname !== '/login') {
      navigate('/login');
    } else if (user.data && location.pathname === '/login') {
      navigate('/');
    }
  }, [location.pathname, navigate, user, user.error]);

  const value = useMemo(
    () => ({
      user: user.data,
    }),
    [user.data],
  );

  return (
    <AuthProviderContext.Provider value={value}>
      {props.children}
    </AuthProviderContext.Provider>
  );
}

function useCurrentUserApiRequest() {
  return useSWRImmutable<User>(
    `${EndpointUrl}/auth/user`,
    async (url: string) => {
      const token = localStorage.getItem('token');

      const userResponse = await fetch(url, {
        headers: {
          'x-finsight-token': token || '',
        },
      });

      if (!userResponse.ok) {
        throw new Error('Unauthorized');
      }

      const userData = (await userResponse.json()) as User;

      return userData;
    },
  );
}

export function useCurrentUser() {
  const context = useContext(AuthProviderContext);

  return context.user
    ? {
        ...context.user,
        token: localStorage.getItem('token'),
      }
    : null;
}
