import { Icon, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { FinsightEntityField } from 'shared';
import { formatName } from 'utils/fields';

export function EnumRenderer({
  field,
  value,
  editable,
  onChange,
}: {
  field: FinsightEntityField;
  value: unknown;
  editable?: boolean;
  onChange?: (value: unknown) => Promise<void>;
}) {
  if (typeof value !== 'number' || !Array.isArray(field.schema.properties))
    return <Typography>Invalid Enum</Typography>;

  if (editable) {
    return (
      <TextField
        select
        label={formatName(field.name ?? '')}
        placeholder={
          field.schema.default
            ? field.schema.properties[Number(field.schema.default)].name
            : undefined
        }
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
      >
        {field.schema.properties
          .map((x, i) => ({ ...x, index: i }))
          .sort((a, b) => (b.order ?? 0) - (a.order ?? 0))
          .map((prop) => (
            <MenuItem key={prop.index} value={prop.index}>
              <Stack direction="row" gap={1} alignItems="center">
                {prop.ui?.adornments?.start?.icon && (
                  <Icon
                    sx={{
                      color: prop.ui?.adornments?.start?.color,
                      fontSize: 26,
                    }}
                  >
                    {prop.ui?.adornments?.start?.icon}
                  </Icon>
                )}
                {formatName(prop.name ?? '')}
              </Stack>
            </MenuItem>
          ))}
      </TextField>
    );
  }

  return (
    <Stack direction="row" gap={2} alignItems="center">
      {field.schema.properties[value].ui?.adornments?.start?.icon && (
        <Icon
          sx={{
            color: field.schema.properties[value].ui?.adornments?.start?.color,
            fontSize: 26,
          }}
        >
          {field.schema.properties[value].ui?.adornments?.start?.icon}
        </Icon>
      )}
      <Typography>
        {formatName(field.schema.properties[value].name ?? '')}
      </Typography>
    </Stack>
  );
}
