"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FinsightEntityUpdateRequestSchema = void 0;
var zod_1 = require("zod");
exports.FinsightEntityUpdateRequestSchema = zod_1.z.object({
    name: zod_1.z.string().optional(),
    description: zod_1.z.string().optional(),
    parent: zod_1.z.union([zod_1.z.string(), zod_1.z.null()]).optional(),
    created: zod_1.z.string().datetime().optional(),
    extraFields: zod_1.z.record(zod_1.z.unknown()).optional(),
});
