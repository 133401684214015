import { Close } from '@mui/icons-material';
import {
  Alert,
  CircularProgress,
  IconButton,
  Slide,
  Snackbar,
  SnackbarProps,
  Stack,
  Typography,
} from '@mui/material';
import React, { createContext, useContext, useMemo, useState } from 'react';

type Options = SnackbarProps & {
  loading?: boolean;
  variant?: 'success' | 'error' | 'warning';
};

type NotificationConfig = {
  id: string;
  message: string;
  options: Options;
};

type NotificationContextType = {
  // eslint-disable-next-line no-unused-vars
  showNotification: (message: string, options?: Options) => void;
};

const NotificationContext = createContext({} as NotificationContextType);

export default function NotificationProvider({
  children,
}: React.PropsWithChildren) {
  const [notifications, setNotifications] = useState<NotificationConfig[]>([]);

  const value = useMemo(
    () => ({
      showNotification: (message: string, options?: Options) => {
        setNotifications((prev) => [
          ...prev,
          { id: window.crypto.randomUUID(), message, options: options || {} },
        ]);
      },
    }),
    [],
  );

  console.log(notifications);

  return (
    <NotificationContext.Provider value={value}>
      {children}
      {notifications.map(({ id, message, options }) => {
        const { loading, variant, ...rest } = options;
        return (
          <Snackbar
            {...rest}
            key={id}
            open={true}
            onClose={() =>
              setNotifications((prev) => prev.filter((n) => n.id !== id))
            }
            TransitionComponent={Slide}
          >
            <Alert
              severity={variant}
              action={
                <IconButton>
                  <Close
                    onClick={() =>
                      setNotifications((prev) =>
                        prev.filter((n) => n.id !== id),
                      )
                    }
                  />
                </IconButton>
              }
            >
              <Stack direction="row" alignItems="center">
                {loading && <CircularProgress />}
                <Typography>{message}</Typography>
              </Stack>
            </Alert>
          </Snackbar>
        );
      })}
    </NotificationContext.Provider>
  );
}

export function useNotifications() {
  const context = useContext(NotificationContext);

  return {
    showNotification: (message: string, options?: Options) =>
      context.showNotification(message, options),
  };
}
