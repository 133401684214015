import { EndpointUrl } from 'api/endpoint';
import { useEntityFetcher } from 'api/entity';
import { useEffect, useState } from 'react';
import {
  ScratchpadChannel,
  ScratchpadChannelCreateRequest,
  ScratchpadChannelCreateRequestSchema,
  ScratchpadDataMaidStatus,
  ScratchpadMessage,
  ScratchpadMessageCreateRequest,
  ScratchpadMessageCreateRequestSchema,
} from 'shared';
import useSWR, { mutate } from 'swr';

export function useScratchpadChannels() {
  return useEntityFetcher<ScratchpadChannel[]>('scratchpad/channels');
}

export async function createScratchpadChannel(
  data: ScratchpadChannelCreateRequest,
) {
  const payload = ScratchpadChannelCreateRequestSchema.parse(data);
  const response = await fetch(`${EndpointUrl}/scratchpad/channel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-finsight-token': localStorage.getItem('token') || '',
    },
    body: JSON.stringify(payload),
  });

  mutate(`${EndpointUrl}/scratchpad/channels`);

  return response;
}

export function useScratchpadChannelMessages(channelId: string | undefined) {
  return useEntityFetcher<ScratchpadMessage[]>(
    channelId ? `scratchpad/channel/${channelId}/messages` : null,
  );
}

export async function createScratchpadMessage(
  data: ScratchpadMessageCreateRequest,
) {
  const payload = ScratchpadMessageCreateRequestSchema.parse(data);
  const response = await fetch(
    `${EndpointUrl}/scratchpad/channel/${payload.channel_id}/message`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-finsight-token': localStorage.getItem('token') || '',
      },
      body: JSON.stringify(payload),
    },
  );

  mutate(`${EndpointUrl}/scratchpad/channel/${payload.channel_id}/messages`);

  return response;
}

export async function deleteScratchpadMessage(channelId: string, id: string) {
  const response = await fetch(`${EndpointUrl}/scratchpad/message/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-finsight-token': localStorage.getItem('token') || '',
    },
  });

  mutate(`${EndpointUrl}/scratchpad/channel/${channelId}/messages`);

  return response;
}

export function useScratchpadMessageDataMaidStatus(
  messageId: string | undefined,
) {
  const [cached, setCached] = useState<ScratchpadDataMaidStatus | null>(null);

  const response = useSWR(
    EndpointUrl && messageId && !cached
      ? `${EndpointUrl}/scratchpad/message/${messageId}/datamaid`
      : null,
    async (url) => {
      const response = await fetch(url, {
        headers: {
          'x-finsight-token': localStorage.getItem('token') || '',
        },
      });
      if (!response.ok) return null;
      const data = await response.json();
      return data as ScratchpadDataMaidStatus;
    },
    {
      refreshInterval: 1000,
    },
  );

  useEffect(() => {
    if (
      !cached &&
      (response.data?.status === 'success' || response.data?.status === 'fail')
    ) {
      setCached(response.data);
    }
  }, [cached, response.data]);

  return cached ? cached : response.data;
}
