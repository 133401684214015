"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommoditySchema = void 0;
var zod_1 = require("zod");
var entity_1 = require("./entity");
exports.CommoditySchema = entity_1.EntitySchema.extend({
    brand: zod_1.z.string(),
    price: zod_1.z.number(),
    quantity: zod_1.z.number(),
    unit: zod_1.z.string().default('unit'),
    type: zod_1.z.literal('commodity'),
    imageUrl: zod_1.z.union([zod_1.z.string(), zod_1.z.null()]).default(null),
    parent: zod_1.z.union([entity_1.ProjectRefSchema, entity_1.TaskRefSchema]),
});
