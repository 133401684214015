import { useCurrentUser } from 'api/providers/authProvider';
import { useWorkspaces } from 'api/workspace';

type PermissionResponse = {
  allowed: boolean;
  message: string;
};

function allow(message: string): PermissionResponse {
  return { allowed: true, message };
}

function deny(message: string): PermissionResponse {
  return { allowed: false, message };
}

export function useUserPermissions() {
  const user = useCurrentUser();
  const workspaces = useWorkspaces();

  return {
    workspaces: {
      create(): PermissionResponse {
        if (!user || !workspaces.data) return deny('You must be logged in');

        switch (user.subscription) {
          case 'free':
            return deny('Users on the free plan cannot create workspaces');
          case 'pro':
            if (workspaces.data?.length < 3) return allow('');
            else
              return deny(
                'Users on the pro plan can only create up to 3 workspaces',
              );
          case 'business':
            if (workspaces.data?.length < 5) return allow('');
            else
              return deny(
                'Users on the business plan can only create up to 5 workspaces',
              );
          case 'enterprise':
            return allow('');
          default:
            return deny('Unknown subscription');
        }
      },
    },
  };
}
