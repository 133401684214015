import { Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import moment from 'moment';
import React from 'react';
import { FinsightEntityField } from 'shared';
import { formatName } from 'utils/fields';

export function DateRenderer({
  field,
  value,
  editable,
  onChange,
}: {
  field: FinsightEntityField;
  value: unknown;
  editable?: boolean;
  onChange?: (value: unknown) => Promise<void>;
}) {
  if (editable) {
    return (
      <DateTimePicker
        label={formatName(field.name ?? '')}
        value={value ? moment(`${value}`) : null}
        onChange={(date) => onChange?.(date?.toISOString())}
      />
    );
  }

  if (!(value instanceof Date)) return <Typography>Invalid Date</Typography>;
  return <Typography>{moment(value).calendar()}</Typography>;
}
