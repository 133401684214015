import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { EndpointUrl } from 'api/endpoint';
import { useCurrentUser } from 'api/providers/authProvider';
import moment from 'moment';
import React from 'react';
import useSWR from 'swr';

export function AccountPage() {
  const currentUser = useCurrentUser();

  const devices = useAccountDevices();

  return (
    <Stack px={2} py={6} gap={4}>
      <Typography variant="h4">My Account</Typography>
      <Stack direction="row" alignItems="center" gap={2}>
        <Avatar
          sx={{
            width: 56,
            height: 56,
            fontSize: '1.7em',
          }}
        >
          {currentUser?.name
            .split(' ')
            .slice(0, 2)
            .map((name) => name[0])
            .join('')}
        </Avatar>
        <Stack>
          <Typography variant="h5">{currentUser?.name}</Typography>
          <Typography>{currentUser?.email}</Typography>
        </Stack>
      </Stack>
      <Divider />
      <Typography variant="h5">Devices</Typography>
      <Stack>
        {devices.data?.map((device) => (
          <Stack
            key={device.public_id}
            border="1px solid"
            borderColor="divider"
            p={2}
            borderRadius={2}
          >
            <Typography variant="h6">
              {device.device?.vendor} {device.device?.model}
            </Typography>
            <Typography>
              {device.os?.name} {device.os?.version}
            </Typography>
            <Typography>
              {device.browser?.name} {device.browser?.version}
            </Typography>
            <Typography>Ip Address: {device.ip_address}</Typography>
            <Typography>Login: {moment(device.created).calendar()}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

function useAccountDevices() {
  const user = useCurrentUser();

  return useSWR<DeviceInfo[]>(
    user?.token ? `${EndpointUrl}/account/devices` : null,
    async (url) => {
      const response = await fetch(url, {
        headers: {
          'x-finsight-token': user?.token ?? '',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch devices');
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        throw new Error('Invalid response');
      }

      return data;
    },
    {
      revalidateOnFocus: false,
    },
  );
}

type DeviceInfo = {
  ua: string;
  ip_address?: string;
  public_id: string;
  created: string;
  browser?: {
    name?: string;
    version?: string;
    major?: string;
  };
  cpu?: unknown;
  device?: {
    model?: string;
    vendor?: string;
  };
  engine?: {
    name?: string;
    version?: string;
  };
  os?: {
    name?: string;
    version?: string;
  };
};
