import { History } from '@mui/icons-material';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { RichTreeViewPro } from '@mui/x-tree-view-pro';
import React from 'react';

export default function DatamaidPage() {
  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Typography variant="h4">Data Maid AI</Typography>
          <Typography>
            An all-in-one solution for importing data into finsight
          </Typography>
        </Stack>
        <Button startIcon={<History />}>History</Button>
      </Stack>
      <Stack
        border="1px dashed"
        borderColor="primary.main"
        borderRadius={3}
        p={2}
        height="300px"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>
          Drop csv, xml, txt, png, jpg, pdf, etc. files here
        </Typography>
      </Stack>
      <Divider />
      <Stack>
        <Typography>Import from My Documents:</Typography>
        <RichTreeViewPro
          items={[
            {
              id: 'scratchpad',
              label: 'Scratch Pad (1)',
              children: [
                {
                  id: 'scratchpad.lemonade_stand',
                  label: 'Lemonade Stand (1)',
                  children: [
                    {
                      id: 'scratchpad.lemonade_stand.invoice',
                      label: 'alibaba_cups_invoice.pdf',
                    },
                  ],
                },
              ],
            },
            {
              id: 'projects',
              label: 'Projects (4)',
              children: [
                {
                  id: 'projects.shed',
                  label: 'Shed (1)',
                  children: [
                    {
                      id: 'projects.shed.nfpa',
                      label: 'nfpa_nec_2017.pdf',
                    },
                  ],
                },
                {
                  id: 'projects.gokart',
                  label: 'Go Kart (3)',
                  children: [
                    {
                      id: 'projects.gokart.motor',
                      label: 'Motenergy_ME1401_Motor_CAD_Schematic.pdf',
                    },
                    {
                      id: 'projects.gokart.chasis',
                      label:
                        'mark_andrews_gokart_chasiss_diy_home_20200420_2030.pdf',
                    },
                    {
                      id: 'projects.gokart.controller',
                      label: 'Fardriver_N72120_guide.pdf',
                    },
                  ],
                },
              ],
            },
            {
              id: 'docs',
              label: 'Documentation (0)',
            },
            {
              id: 'storage',
              label: 'Document Storage (1)',
              children: [
                {
                  id: 'storage.random',
                  label: 'random docs sort later (1)',
                  children: [
                    {
                      id: 'storage.random.1',
                      label: 'Pearson Learning - Organic Chemistry.pdf',
                    },
                  ],
                },
                {
                  id: 'storage.random2',
                  label: 'Public Documents (0)',
                },
              ],
            },
          ]}
        ></RichTreeViewPro>
      </Stack>
    </Stack>
  );
}
