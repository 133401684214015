"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FinsightEntityCreateRequestSchema = void 0;
var zod_1 = require("zod");
exports.FinsightEntityCreateRequestSchema = zod_1.z.object({
    type: zod_1.z.string(),
    name: zod_1.z.string(),
    description: zod_1.z.string().optional(),
    parent: zod_1.z.string().optional(),
    created: zod_1.z.string().datetime().optional(),
    extraFields: zod_1.z.record(zod_1.z.unknown()).optional(),
});
